import React from 'react';
import Select from 'react-select';
import muniData from './data/muniData2024.json'
import RadioButton from './RadioButton';
import './App.css';


var muniOptions = new Array(Object.keys(muniData).length);
Object.keys(muniData).forEach(function (key) {
    muniOptions.push({ label: muniData[key]['MUNICIPALITY'], value: key });
})

class App extends React.Component {
  state = {
    selectedMuni: null,
    appraisedValue: null,
    yearlyTax: null,
    propertyType: 'home',
  };

  handleMuniChange = selectedOption => {
    var muni = muniData[selectedOption.value]
    this.setState(
      { selectedMuni: muni },
      () => {
        if (muni['FLAT_RATE']) {
          alert(`You have selected a special municipality. The rate ` +
            `is described as: ${muni['FLAT_RATE']}`);
        }
        else {
          this.updateTax()
        }
      }
    );
  };

  handleAppraisalChange = e => {
    this.setState(
      { appraisedValue: parseFloat(e.target.value) }, () => this.updateTax()
    );
  };

  radioChangeHandler = e => {
    this.setState(
      { propertyType: e.target.value }, () => this.updateTax()
    );
  };

  updateTax = () => {
    var assessedValue, tax;
    if (this.state.appraisedValue && this.state.selectedMuni) {
      assessedValue = this.state.appraisedValue * 0.7
      tax = (assessedValue * this.millRate()) / 1000
      this.setState({ yearlyTax: tax })
    }
  };

  millRate = () => {
    var muni = this.state.selectedMuni
    var home = this.state.propertyType === 'home'
    if (muni['FLAT_RATE'])
    {
      return 0;
    }
    else if (muni['MILL_RATE_VEHICLE'] && !home)
    {
      return muni['MILL_RATE_VEHICLE'];
    }
    else if (muni['MILL_RATE_PROPERTY'] && home)
    {
      return muni['MILL_RATE_PROPERTY'];
    }
    else
    {
      return 0;
    }
  };

  toCurrency = numValue => {
    if(numValue) {
      return numValue.toLocaleString(
      'en-US', { style: 'currency', currency: 'USD' })
    }
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <div className="App container">
        <div class="row">
          <div class="left-align col l6 s12">
            <h5>Select Your Municipalilty / District</h5>
            <Select
              value={selectedOption}
              onChange={this.handleMuniChange}
              options={muniOptions}
            />
          </div>
        </div>
        <div class="row">
          <div class="property-type col l6 s12">
            <RadioButton
              changed={ this.radioChangeHandler }
              id="1"
              isSelected={ this.state.propertyType === "home" }
              label="Home*"
              icon="home"
              value="home"
            />
            <RadioButton
              changed={ this.radioChangeHandler }
              id="2"
              isSelected={ this.state.propertyType === "auto" }
              label="Vehicle"
              icon="directions_car"
              value="auto"
            />
          </div>
          </div>
        <div class="row">
          <div class="col s12 l6 left-align" id="mill-rate-container">
            <h5>
              2024 Mill Rate: {this.state.selectedMuni ? this.millRate() : ' - - - '}
            </h5>
          </div>
        </div>
        <div class="row">
          <form class="col s12">
            <div class="row">
              <div class="col s12 l6 left-align flow-text" id="appraised-text">
                Appraised Value
                <span class="hide-on-med-and-down"> (Fair Market Value)</span></div>
            </div>
            <div class="row">
              <div class="input-field col s12 l6">
                <i class="material-icons prefix vertical-align">attach_money</i>
                <input id="apprasied_val" type="number" class="validate" onInput={this.handleAppraisalChange} placeholder="0.00"/>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col s12 l6 left-align">
            <div class="card white">
              <div class="card-content">
                <span class="card-title flow-text">Yearly Tax </span>
                <h5>{this.toCurrency(this.state.yearlyTax)}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col s12 l6">
            <div class="card white">
              <div class="left-align card-content">
                *In Connecticut, the tax value of your home tax rate is determined as follows:<br/>
                (Assessed Value * Mill Rate) / 1000<br/>
                - The assessed value is 70 percent of the appraised (fair market value) determined by the assessor.<br/>
                Example: If the assessor determines that the market value of a home is $100,000, the assessed value will be $70,000.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default App;
