import React from "react";

const RadioButton = (props) => {
    return (
      <div class="col s6">
        <label htmlFor={props.id}>
          <input class="with-gap" id={props.id} onChange={props.changed} value={props.value} type="radio" checked={props.isSelected} />
          <span class="black-text">
            <i class="material-icons medium hide-on-small-only prefix black-text">{props.icon}</i>
            <i class="material-icons small hide-on-med-and-up prefix black-text">{props.icon}</i>
            <span>{props.label}</span>
          </span>
        </label>
      </div>
    );
}

export default RadioButton;
